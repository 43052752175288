import { DB_API_KEY, DEV_API_KEY, STRIPE_API_KEY, WEATHER_API_KEY } from '../keys';

const firebaseConfig ={
  apiKey: DEV_API_KEY,
  authDomain: 'gametimehero-dev.firebaseapp.com',
  databaseURL: 'https://gametimehero-dev-default-rtdb.firebaseio.com',
  projectId: 'gametimehero-dev',
  storageBucket: 'gametimehero-dev.appspot.com',
  messagingSenderId: '714560815466',
  appId: '1:714560815466:web:f57f5acc4764077cc7e513',
  measurementId: 'G-CLN2Q34155',
};

export const environment = {
  production: true,
  useEmulators: false,
  envName: 'dev',
  realtimeDb: 'https://gametimehero-dev-default-rtdb.firebaseio.com',
  firestore: 'https://us-central1-gametimehero-dev.cloudfunctions.net',
  googleMaps: DB_API_KEY,
  firebase: firebaseConfig,
  stripe: STRIPE_API_KEY,
  root: 'https://gametimehero-dev.web.app/login',
  weatherApi: WEATHER_API_KEY,
};
